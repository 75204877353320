.start-button {
    background-color: #9e9e9e;
    color: #ffffff;
    font-size: 1.5vw;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    width: 100%;
    height: 3vw;
    font-family: "Viga", sans-serif;
    display: block;
}

.start-button:hover {
    background-color: #5452E1;
}



@media only screen and (max-width: 600px){
    .start-button {
        width: 40vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
  
}



@media only screen and (min-width: 900px) and (max-width: 1200px){
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}
