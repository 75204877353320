.login-title {
    font-size: 1.8vw;
    color: #5452E1;
    text-align: center;
}

/* Botones */
.login-button {
    border-radius: 2vw;
    border: none;
    width: 100%;
    height: 2.5vw;
    color: white;
    font-size: 1.5vw;
    font-weight: lighter;
    background-color: #5452E1;
    position: relative;
    font-family: "Viga", serif;
    margin-top: 4%;
    margin-bottom: 4%;
    cursor: pointer;
}



.login-google {
    border-radius: 2vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border: none;
    cursor: pointer;
    width: 58%;
    height: 2.5vw;
    color: gray;
    font-size: 1.2vw;
    background-color: ghostwhite;
    font-family: "Viga", serif;
    margin-top: 4%;
    margin-bottom: 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.6vw;
}

.login-facebook {
    border-radius: 2vw;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 58%;
    height: 2.5vw;
    color: white;
    font-size: 1.2vw;
    background-color: #1877f2;
    font-family: "Viga", serif;
    z-index: 0;
    margin-bottom: 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.6vw;
}

/* Iconos botones */
.icono {
    width: 1.8vw;
    padding-left: 0.6vw;
}

/* Linea de texto - o - */
.o-text {
    color: #868686;
    position: absolute;
    top: 55%;
    left: 2px;
}

.linea-conjunto {
    display: flex;
    align-items: center;
    margin-top: 4%;
}

.line {
    border-bottom: 0.2px solid gainsboro;
    flex-grow: 1;
    margin-right: 10px;
    margin-left: 10px;
    
}

.letter {
    font-size: 1vw;
    color: #868686;
}

.login-redes-container {
    display: flex;
    flex-direction: column; /* Los botones estarán uno encima del otro */
    align-items: center;
}

/* Texto Inferior */
.texto-inferior-login{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8vw;
}

.login-undertext {
    color: gray;
}

@media only screen and (max-width: 600px)
    {
        .login-title {
            font-size: 6vw;
            margin-bottom: 0;
        } 
        .loading-button {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        
            
        .linea-conjunto {
            margin-top: 7%;
            position: relative;
        }
        
        
        .line {
            border-bottom: 0.1vw solid gainsboro;
            flex-grow: 1;
            margin-right: 2vw;
            margin-left: 2vw;

        }
        
        .letter {
            
            font-size: 2.7vw;
            color: #868686;
        } 
        
        /* Texto Inferior */
        .texto-inferior-login{
            font-size: 2.6vw;
        
            
        }
        .forgot-password{
            margin-bottom: 2vw;
        }
        
        .login-undertext {
            color: gray;
            margin-top: 1vw;
        }

        .login-google {
            margin-top: 6vw;
            height: 8vw;
            font-size:2.8vw;
            width: 75%;
            border-radius: 5vw;
        }
        .login-facebook {
          
            height: 8vw;
            font-size: 2.8vw;
            width: 75%;
            margin-bottom: 12vw;
            border-radius: 5vw;
        }

        .icono {
            width: 4vw;
        }
    }