/* Sidebar */
.sidebar {
    width: 12%;
    background-color: #203139;
    border-bottom-left-radius: 1vw;
    border-top-left-radius: 1vw;
    position: absolute;
    left: 8%;
    z-index: 0;
    height: 100%;
    top: 8%;
    bottom: 0;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15);
}
.disabled-arrow {
    filter: grayscale(100%);
    cursor: not-allowed;
  }

.sidebar-menu {
    width: 9%;
    position: absolute;
    left: 8.3%;
    z-index: 0;
    height: 80%;
    top: 15.5%;
}

.sideimg {
    width: auto;
    height: 50%;
    cursor: pointer;
}

.sidebar-text {
    color: #fff;
    font-size: 0.8vw;
}

.animation {
    height: 20%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.animation:hover,
.animation.active {
    transform: scale(1.2);
    filter: brightness(110%);
    background-color: #5452E1;
    border-bottom-left-radius: 1vw;
    border-top-left-radius: 1vw;
}

.animation.disabled:hover {
    background-color: #999;
    border-bottom-left-radius: 1vw;
    border-top-left-radius: 1vw;
}

.grayed-out {
    filter: grayscale(100%);
}

.up-image {
    position: absolute;
    top: -7%;
    width: 1.3vw;
    height: 1.3vw;
    left: 38%;
    cursor: pointer;
}

.down-image{
    position: absolute;
    width: 1.3vw;
    height: 1.3vw;
    transform: rotate(-180deg);
    bottom: -13%;
    left: 38%;
    cursor: pointer;
}

.disabledButton {
    pointer-events: none;
    opacity: 0.5; 
}



@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .sidebar {
        display: none;
    }
    .sidebar-menu {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}
