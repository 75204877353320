.title-restore-password {
    font-size: 1.8vw;
    color: #5452E1;
    text-align: center;
}

.text-restore-password {
    font-size: 0.8vw;
    color: #868686;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5vw;
}
.button-link-restore{
    
    border-radius: 2vw;
    border: none;
    width: 100%;
    height: 2.5vw;
    color: white;
    font-size: 1.5vw;
    background-color: #5452E1;
    position: relative;
    font-family: "Viga", serif;
    margin-top: 6%;
    margin-bottom: 4%;
}

.button-link-restore:hover {
    cursor: pointer;
}

.input-restore-password{
    margin-top: 1.5vw;
}

.restore-password-undertext{
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}

.container-button-restore {
    text-align: center; 
    margin-top: 4vw;
}

.button-login-restore {
    height: 2.8vw;
    width: 20vw;
    border-radius: 2vw;
    border-color: #5452E1;
    background-color: white;
    font-size: 1.4vw;
    font-family: "Viga", serif;
    color: #5452E1;
    border: 0.2px solid;
    box-shadow: 0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54);
    cursor: pointer;
    
}

.icon-button-restore {
    position: relative;
    left: -1vw;
}


@media only screen and (max-width: 600px){
    .title-restore-password  {
     font-size: 6vw;
     margin-top: 12vw;
    }
    .text-restore-password{
        font-size: 2.5vw;
        text-align: center;
        display: flex;
        align-items: center;
        margin-top: 6vw;
    }
    .button-link-restore{
        width: 30vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 7.5vw;
        font-size: 2.5vw;
        border-radius: 36px;
        margin-top: 8vw;
        margin-bottom: 4vw;
    } 
    .linea-conjunto{
        display: none;
    }
    .restore-password-undertext{
        font-size: 3vw;
        padding-bottom: 4vw;
    }
    .button-login-restore{
        width: 30vw;
        height: 7.5vw;
        font-size: 2.5vw;
        border-radius: 36px;
        padding-bottom: 10vw;

    }
    .icon-button-restore{
        font-size: 5vw;
        top: 2.4vw;
    }
    .container-button-restore{
        padding-bottom: 80px;
    }
    
    
}