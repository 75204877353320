.messageAddres {
    width: 14vw;
    background-color: #5452E1;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    right: -14vw;
    z-index: -2;
    height: 17vw;
    top: 10%;
    bottom: 0;
    text-align: left;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15)
}

.message-container-addres{
    font-size: 1.5vw;
    margin-right: 8%;
    margin-left: 8%;
    margin-top: 90%;
    font-family: 'Heveltica';
}

.img-alert {
    z-index: 1;
    height: auto;
    transform: rotate(10deg);
    position: absolute;
    top: -13%;
    right: -10%;
    width: 30%;
}

.message-text-default {
    margin-top: 13%;
    font-size: 1vw;
    color: white;
    margin-left: 8%;
    margin-right: 8%;
}
.message-text-color {
   color: #ffe048;
} 

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .messageAddres{
        display: none;
    }
}