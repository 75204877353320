/* MainContent */
.main-panel {
    font-family: 'Viga', sans-serif;
    display: flex;
    width: 65%;
    background-color: #fff;
    border-radius: 1vw;
    z-index: 2;
    position: absolute;
    top: 8%;
    height: 100%;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15);
    justify-content: center;
    align-items: center;
}

.calculatorExtra {
    position: absolute;
    width: 28%;
    height: auto;
    top: 63%;
    right: -16%;
    transform: rotate(10deg);
}
.loveBox{
    position: absolute;
    width: 40%;
    height: auto;
    top: 50%;
    right: -25%;
}
.page-container {
    width: 100%;
    height: 95%;
}


@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .main-panel {
        width: 90%;
        height: auto;
        padding-bottom: 5px;
    }
    .calculatorExtra {
        display: none;
    }
    .loveBox{
        display: none;
    }
    .main-panel{
        box-shadow: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}