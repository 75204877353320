/* LayoutBase */
.layout {
    height: 100vh;
    min-width: 250px;
}

.header {
    width: 100%;
}

.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    margin: 0;
    font-family: 'Viga', sans-serif;
}

/* Colores generales de bordes campos */
.MuiFormControl-root:hover .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #5452E1;
}

.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #5452E1;
}

.MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border-color: #f4f7f6;
}

/* Colores generales de check box */
/* Cambiar el color cuando se pasa el ratón sobre el checkbox */
.MuiCheckbox-root:hover .MuiSvgIcon-root {
    fill: #5452e1;
}

/* Cambiar el color de fondo cuando el checkbox está marcado (pulsado) */
.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    color: #5452e1;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}
