.main-register-button {
    background-color: #5452E1;
    color: #ffffff;
    font-size: 1.5vw;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    width: 100%;
    height: 3vw;
    font-family: "Viga", sans-serif;
    display: block;
}


.underbutton-text {
    text-align: center;
    font-size: 0.9vw;
    color: rgb(68, 67, 67);
}

a {
    color: #5452E1;
    text-decoration: none;
}

.checkbox-register {
    margin-left: 100px;
    margin-top: 5px;
}

.a-checkbox {
    color: #5452E1;
    text-decoration: none;
}

.checkbox-conjunto {
    margin-top: 8px;
    font-size: 0.8vw;
    color: rgb(68, 67, 67);
    display: flex;
    align-items: center;
    gap: 10px;
}

.buttom-register1-pack{
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
}

.checkbox-conjunto .text-checkbox {
    margin: 0;
}

.page-container-register-1{
    width: 85%;
    height: 95%;
}

.captcha-zone {
    height: 8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px){
    /* Estilos para dispositivos pequeños (xs) */
    .checkbox-conjunto {
        margin-top: 5vw;
        text-align: center;
        font-size: 3.3vw;
        gap: 0;
    }

    .captcha-zone {
        height: 25vw;
        transform: scale(0.80);
    }

    .checkbox{
        font-size: 20vw;
    }

    .main-register-button {
        width: 50vw;
        height: 9vw;
        font-size: 4vw;
        position: relative;
        margin-top: 13vw;
        left: 15%;
    }

    .underbutton-text {
        margin-top: 5vw;
       font-size: 2.3vw;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
    /* Ajustes de tamaño Captcha*/
    .captcha-zone{
        transform: scale(0.3);
        height: 4vw;
    }

}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
    /* Ajustes de tamaño Captcha*/
    .captcha-zone{
        transform: scale(0.70);
        height: 5vw;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
    .captcha-zone {
        height: 6vw;
    }

}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
    .captcha-zone {
        height: 5vw;
    }
}