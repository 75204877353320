.title-newpassword {
    text-align: center;
    color: #5452E1;
    font-size: 2vw;
    font-weight: lighter;
    margin-top: 4vw
    
}

.parrafo-newpassword {
    font-size: 1vw;
    color: gray;
    text-align: center;
    margin-bottom: 1.5vw;
}
.captcha-zone-newpassword{
    margin-top: 2vw;
    margin-bottom: 1.5vw;
    height: 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button-newpassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vw;

}

.final-text-newpassword {
    text-align: center;
    color: gray;
    font-size: 1vw;
}

.newpassword-undertext {
    color: gray;
    
}

@media only screen and (max-width: 600px) {

    .title-newpassword {
        margin-top: 11vw;
        font-size: 6.5vw;
    }

    .parrafo-newpassword {
        font-size: 2.5vw;
    }

    .captcha-zone-newpassword {
        margin-top: 13vw;
        height: 25vw;
        transform: scale(0.80);
    }

    .button-newpassword {
        margin-top: 12vw;
    
    }
    .final-text-newpassword{
        font-size: 3vw;
        margin-top: 8vw;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    /* Estilos para dispositivos pequeños (sm) */
    .captcha-zone-newpassword {
        height: 3vw;
        transform: scale(0.3);
    }
}