.switch-container {
    display: flex;
    background-color: #5452e1;
    border-radius: 3vw;
    padding: 0.2vw;
    width: 21vw;
    justify-content: space-around;
    font-size: 0.9vw;
}

.switch-option {
    text-align: center;
    color: white;
    padding: 0.1vw 2.5vw;
    user-select: none; /* Evita la selección del texto al hacer doble clic */
    cursor: pointer;
    border-radius: 3vw; /* Redondear los bordes de las opciones */
    background-color: transparent; /* Fondo transparente por defecto */
    transition: background-color 0.3s; /* Suavizar la transición de fondo */
    white-space: nowrap;
}

.switch-option.selected {
    background-color: #fff; /* Fondo de la opción seleccionada */
    color: #5452e1;
}
@media only screen and (max-width: 600px){
    .switch-container {
        width: 70vw;
        font-size: 5vw;
        padding: 1vw;
        border-radius: 4vw;
    }

    .switch-option {
        padding: 1.5vw 2.5vw;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
}

@media only screen and (min-width: 1200px) and (max-width: 1536px) {
    /* Estilos para dispositivos grandes (lg) */
}
