/* RegisterMessage */
.register-message {
    width: 25%;
    background-color: #a2a7f9;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    left: 8%;
    z-index: 0;
    height: 100%;
    top: 8%;
    bottom: 0;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15)
}

.register-message-text {
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 14%;
    margin-top: 18%;
    font-size: 1.4vw;
    font-family: 'Heveltica';
}

.boxregister-img {
    width: 35%;
    height: auto;
    position: absolute;
    top: 28%;
    z-index: 4;
    left:0;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .register-message {
        display: none;
    }
    .boxregister-img {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}
