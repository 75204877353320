/* RegisterMessage2 */
.register-message-2 {
    width: 23%;
    background-color: #a2a7f9;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    right: 9.5%;
    z-index: 0;
    height: 100%;
    top: 8%;
    bottom: 0;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15)
}

.message-container-2{
    font-size: 1.5vw;
    margin-right: 8%;
    margin-left: 8%;
    margin-top: 38%;
    font-family: 'Heveltica';
    text-align: right;
}

.text-message-2{
    color: white;
}

.text-message-2-yellow{
    color: yellow;
}

.meesage-image{
    width: 28%;
    height: auto;
    position: absolute;
    z-index: 2;
    top: 55%;
    left: 67%;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .register-message-2 {
        display: none;
    }
    .meesage-image{
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}
