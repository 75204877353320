/* RegisterMainContent2 */

.main-panel-register-2 {
    font-family: 'Viga', sans-serif;
    display: flex;
    width: 58%;
    background-color: #fff;
    border-radius: 20px;
    z-index: 2;
    position: absolute;
    top: 8%;
    right: 30%;
    height: 100%;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15);
    justify-content: center;
    align-items: center;
}

.page-container-register-2 {
    width: 80%;
    height: 80%;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .main-panel-register-2 {
        width: 95%;
        height: auto;
        padding-bottom: 5px;
        left: auto;
        box-shadow: none;
        position: static;
       
    } .main-panel-register {
        box-shadow: none;
    }
}
@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}
@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}
@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}
@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}