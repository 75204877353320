.message {
    width: 15%;
    background-color: #5452E1;
    border-bottom-right-radius: 2vw;
    border-top-right-radius: 20px;
    position: absolute;
    z-index: 0;
    height: 50%;
    top: 15%;
    right: 3%;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15)
}

.messagecontainer {
    position: relative;
}

.colormessage {
    color: #ffe048;
}

.alert-img {
    z-index: 1;
    height: auto;
    transform: rotate(10deg);
    position: absolute;
    top: -20%;
    right: -10%;
    width: 30%;
}

/* Texto de Mensaje Lateral */
.text-message-container {
    padding: 5%;
    padding-left: 10%;
    text-overflow: ellipsis;
    height: 90%;
}

.text-message {
    color: #fff;
    font-size: 0.9vw;
    line-height: 1;
    word-wrap: break-word;
    display: block;
    margin-top: 1.8em;
    font-family: 'Heveltica';
}

.text-message2 {
    color: #ffe048;
    font-size: 0.9vw;
    line-height: 0.78vw;
    word-wrap: break-word;
    display: block;
    margin-bottom: 2em;
    font-family: 'Heveltica';
    
}


@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .message {
        display: none !important;
    }
    .messagecontainer {
        position: relative;
    }
    .alert-img {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}
