.title {
    color: #5452e1;
    font-family: 'Viga', sans-serif;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
    font-family: 'Viga', sans-serif;
}

.checkbox-container {
    font-size: 13px;
}

.checkbox .MuiSvgIcon-root{
    font-size: 1vw;
}

.link {
    color: #5452e1;
    cursor: pointer;
}

.MuiDialog-container {
    max-height: 720px;
}

/* Agrega estos estilos a tu archivo CSS existente o crea uno nuevo */
.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Centra los elementos horizontalmente */
    margin-top: 15px;
}

.text-below-button {
    margin-right: 3px;
    color: #9da19f;
}

.whatsapp-link {
    color: #5452e1;
    text-decoration: none;
}

.whatsapp-icon {
    margin-top: 6px;
    margin-left: 3px;
    margin-right: 2px;
}

.custom-text {
    color: #9da19f;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130%;
}

.captcha {
    font-weight: bolder;
    text-align: center;
}

/* Color active */
.boton-morado {
    background-color: #5452e1;
}


.purple-text {
    color: #5452e1;
}

/* Captcha zone */
.captcha-zone-calculator {
    height: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    /* Estilos para dispositivos extra pequeños (xs) */
    .captcha-zone-calculator {
        height: 25vw;
        transform: scale(0.80);
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    /* Estilos para dispositivos pequeños (sm) */
    .captcha-zone-calculator {
        height: 3vw;
        transform: scale(0.3);
    }
}

@media only screen and (min-width: 900px) and (max-height: 1200px) {
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px) {
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos grandes (xl) */
}