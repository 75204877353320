/* Estructura base */
.register1-layout .content-container {
    display: flex;
    justify-content: center;
    position: relative;
    margin: auto;
    width: 80%;
    aspect-ratio: 16 / 7;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .register1-layout .content-container{
        width: 100%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px){
    /* Estilos para dispositivos pequeños (sm) */
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    /* Estilos para dispositivos medianos (md) */
}

@media only screen and (min-width: 1200px) and (max-width: 1536px){
    /* Estilos para dispositivos grandes (lg) */
}

@media only screen and (min-width: 1536px) {
    /* Estilos para dispositivos extra grandes (xl) */
}
