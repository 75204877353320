.register-message-2-1 {
    width: 23%;
    background-color: #5452E1;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    right: -23%;
    z-index: 0;
    height: 15%;
    top: 10%;
    bottom: 0;
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.15);
    font-size:0.9vw ;
    
}

.message-container-2-1 {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    text-indent: 0; 
    text-shadow: -2px 2px 6px rgba(52, 50, 50, 0.48);
    color: white;
    height: 100%; 
}


.orange-text{
    color: orange;
}


.alerta-imagen-prealert{
    width: 28%;
    height: auto;
    position: absolute;
    z-index: 2;
    top: -30%;
    left: 80%;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .register-message-2-1 {
        display: none;
    }
    .meesage-image{
        display: none;
    }
}