.title-direccion {
    text-align: center;
    font-size: 2vw;
    position: relative;
    top: -1vw;
}

.compra-variable {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    color: white;
    padding: 0vw 1vw 0vw 1vw;
    font-size: 1.1vw;
    text-align: center;
    height: 2.7vw;
    position: relative;
}

.general-text {
    font-size: 1vw;
}

.compra-variable-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1.5vw;
    margin-bottom: 1vw;
}

.address-main {
    text-align: center;
    align-items: center;

}

.terminos-text {
    color: #5452E1;
}

.variables-gray-text {
    color: gray;
    font-size: 1vw;
    width: 6vw;
}

.formulario-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    margin-top: 1.2vw;
    margin-bottom: -14px;
}

.span-variable-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
    border-radius: 20px;
    background-color: #0a0a0a;
    width: 15vw;
    color: white;
    font-size: 1.1vw;
    text-align: center;
    height: 2.5vw;
}

.img-alert2 {
    width: 1vw;
    transform: rotate(-10deg);
    position: relative;
    top: 0.2vw;
}

.custom-button1 {
    width: 1.7vw;
    height: 1.7vw;
    color: #5452E1;
    transform: rotate(-90deg);
    position: absolute;
    left: 22%;
    top: 0.4vw;
    cursor: pointer;
}

.custom-button2 {
    width: 1.7vw;
    height: 1.7vw;
    color: #5452E1;
    transform: rotate(90deg);
    position: absolute;
    top: 0.4vw;
    right: 22%;
    cursor: pointer;
}

.address-main .whatsapp-link-1 {
    margin-right: 5px;
}
.typography-content-1{
    margin-top: 2vw;
}

@media only screen and (max-width: 600px) {

    /* Modificaciones para quitar vistas web */
    .title-direccion {
        margin-top: 8vw;
        font-size: 7vw;
    }

    .general-text {
        font-size: 4vw;
        margin-top: 8vw;
        margin-bottom: 8vw;
    }

    .img-alert2 {
        width: 4vw;
    }

    .compra-variable {
        width: 50vw;
        height: 8vw;
        font-size: 3vw;
    }

    .variables-gray-text {
        font-size: 4vw;
        margin-right: 12vw;
    }

    .formulario-container {
        margin-bottom: 5vw;
    }

    .span-variable-container {
        width: 30vw;
        height: 5vw;
        font-size: 2.4vw;
    }

    .custom-button1 {
        width: 7vw;
        height: 7vw;
        left: 4%;
    }

    .custom-button2 {
        width: 7vw;
        height: 7vw;
        right: 4%;
    }

}